import React from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import {
  isLayoutPGSideBySide,
  isLayoutPGOneColumn,
  isLayoutFullPost,
  isLayoutProGallery,
  getIsAppBundle,
  SECTION_HOMEPAGE,
  SECTION_CATEGORY,
} from '@wix/communities-blog-client-common';
import withLayoutProps from '../../hoc/with-layout-props';
import {
  getIsCreatedWithResponsiveEditor,
  getLayoutMargins,
  getLayoutPostSize,
} from '../../selectors/app-settings-selectors';
import { getSection } from '../../selectors/section-selectors';
import { withResponsiveContext } from '../responsive-listener/responsive-context';
import { connect } from '../runtime-context';
import styles from './desktop-header.scss';

const renderMenu = (menu) => {
  return menu && <div className={styles.menu}>{menu}</div>;
};

const DesktopHeader = ({
  left,
  right,
  menu,
  className,
  useResponsiveEditorStyling,
  isAppBundle,
  layoutMargins,
  layoutType,
  layoutPostSize,
}) => {
  const containerClassName = classNames(
    styles.container,
    className,
    'blog-header-background-color',
  );
  const layoutPGSideBySide = isLayoutPGSideBySide(layoutType);
  const layoutPGOneColumn = isLayoutPGOneColumn(layoutType);
  const layoutFullPost = isLayoutFullPost(layoutType);

  const wrapperClassName = classNames(
    styles.header,
    !isLayoutProGallery(layoutType) && styles.notPGLayout,
    isAppBundle &&
      (!isLayoutProGallery(layoutType) || useResponsiveEditorStyling) &&
      styles.withPadding,
  );

  const containerStyles =
    useResponsiveEditorStyling ||
    layoutPGSideBySide ||
    layoutPGOneColumn ||
    !layoutMargins
      ? {}
      : { paddingRight: layoutMargins, paddingLeft: layoutMargins };

  const wrapperStyles = {
    ...((layoutPGSideBySide || layoutPGOneColumn) && {
      maxWidth: layoutPostSize,
    }),
    ...(useResponsiveEditorStyling && layoutFullPost && { maxWidth: 940 }),
  };

  return (
    <div
      style={containerStyles}
      className={containerClassName}
      data-hook="blog-desktop-header-container"
    >
      <div style={wrapperStyles} className={wrapperClassName}>
        {left}
        {!useResponsiveEditorStyling && right}
      </div>
      {renderMenu(menu)}
    </div>
  );
};

DesktopHeader.propTypes = {
  left: PropTypes.node,
  right: PropTypes.node,
  menu: PropTypes.node,
  className: PropTypes.string,
  useResponsiveEditorStyling: PropTypes.bool,
  isAppBundle: PropTypes.bool.isRequired,
  layoutMargins: PropTypes.number,
  layoutType: PropTypes.number.isRequired,
  layoutPostSize: PropTypes.number,
};

const mapRuntimeToProps = (
  state,
  { rootWidth, layoutType, layoutName },
  actions,
  host,
) => {
  const section = getSection(state);
  const layoutMargins = getLayoutMargins({ state });

  const createdWithResponsiveEditor = getIsCreatedWithResponsiveEditor(state);

  const isFeed = [SECTION_HOMEPAGE, SECTION_CATEGORY].includes(section);
  const useResponsiveEditorStyling = createdWithResponsiveEditor && !isFeed;
  return {
    useResponsiveEditorStyling,
    isAppBundle: getIsAppBundle(state),
    layoutMargins,
    layoutType,
    layoutPostSize: getLayoutPostSize({
      state,
      section,
      layoutType,
      layoutName,
      hostWidth: host.dimensions.width,
      rootWidth,
      layoutMargins: useResponsiveEditorStyling ? null : layoutMargins,
    }),
  };
};

export default flowRight(
  withResponsiveContext,
  withLayoutProps(),
  connect(mapRuntimeToProps),
)(DesktopHeader);
